export enum BatchSystemSettingsEnum {
    BulkPrint = 'Bulk Print',
    MFGDate = 'Manufacturing Date As Per GS1',
    PrintQueue = 'Required System Generated PDF',
    Commission = 'Is Required Product Verification Then Commission'
}
export enum MFGDateYearEnum {
    GS149 = '49'
}
export enum PrintStatusEnum {
    Pending,
    Queue,
    Done
}