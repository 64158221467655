import { Component, ViewChild } from '@angular/core';
import { MasterSettingsService } from '../../../services/master-settings.service';
import { AuthenticationService } from '../../../services/auth.service';
import { SharedService } from '../../../services/shared.service';
import { BatchSystemSettingsEnum } from '../../const/enum';
import { NotificationDirective } from '../../../directive/notification.directive';

@Component({
    selector: 'div.sidebar',
    templateUrl: './sidebar.component.html',
    providers: [MasterSettingsService]
    //  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
    isActive = false;
    showMenu = '';
    UserId = '';
    isEnablePrintQueueAccess = false;
    batchSystemSettingsEnum = BatchSystemSettingsEnum;
    @ViewChild(NotificationDirective) notify: NotificationDirective;
    constructor(
        public authService: AuthenticationService,
        public sharedService: SharedService,
        public masterSettingService: MasterSettingsService
    ) {
        this.sharedService.assignViewAsPerRole();
    }

    ngOnInit() {
        var result = sessionStorage.getItem('kevUserDetail');
        var jresult = JSON.parse(result);
        this.UserId = jresult.id;
        this.CheckPrinterQueueEnableStatus();
    }

    eventCalled() {
        this.isActive = !this.isActive;
    }
    addExpandClass(element: any) {
        if (element === this.showMenu) {
            this.showMenu = '0';
        } else {
            this.showMenu = element;
        }
    }
    CheckPrinterQueueEnableStatus() {
        this.masterSettingService.getCodeGenerationTypeList()
            .subscribe(response => {
                let batchsetting = response.BatchSystemSettings.find(x => x.BatchSettingName.toLowerCase() === this.batchSystemSettingsEnum.PrintQueue.toLowerCase());
                if (batchsetting) {
                    this.isEnablePrintQueueAccess = !batchsetting.IsActive;
                }
            }, (Error) => {
                this.notify.DangerMessage(Error);
            });
    }

}
