import {Directive,ElementRef,Input} from "@angular/core";

@Directive ({selector: '[notify]'})

export class NotificationDirective {
    constructor(private el:ElementRef){
       // console.log('Notification Initialized');
       // el.nativeElement.innerHTML = '<div class="alert alert-danger alert-dismissable fade in">Error</div>';
    }

    RenderMessage(message:string,bgclass:string,statustype:string){
        this.el.nativeElement.innerHTML = `<div class="animated fadeInLeft alert `+ bgclass +` alert-dismissable">
                                            <a href="#" class="close" style="margin-right:1%;" data-dismiss="alert" aria-label="close">&times;</a>
                                            <strong>`+ statustype +`</strong>
                                            `+ message +`
                                            </div>`;
        setTimeout(() => { this.el.nativeElement.innerHTML = '';},10000);
        window.scrollTo(0,0);
    }

    SuccessMessage(message:string)
    {
        this.el.nativeElement.innerHTML = `<div class="animated rubberBand alert alert-success alert-bordered alert-dismissable">
        <a href="#" class="close" style="margin-right:1%;" data-dismiss="alert" aria-label="close">&times;</a>
        <span class="text-semibold"><b><i class='icon-checkmark2'></i> Success: </b></span>
        `+ message +`
        </div>`;
        setTimeout(() => { this.el.nativeElement.innerHTML = '';},10000);
        window.scrollTo(0,0);
    }

    DangerMessage(message:string){
        this.el.nativeElement.innerHTML = `<div class="animated shake alert alert-danger alert-bordered alert-dismissable">
        <a href="#" class="close" style="margin-right:1%;" data-dismiss="alert" aria-label="close">&times;</a>
        <span class="text-semibold"><b><i class='icon-notification2'></i></b></span>
        `+ message +`
        </div>`;
        setTimeout(() => { this.el.nativeElement.innerHTML = '';},10000);
        window.scrollTo(0,0);
    }

    DangerMessageList(message:string[]){
        let errorlist:string ="";
        message.forEach(element => {  
            errorlist += ' <li>'+ element +'</li>';
        });
        this.el.nativeElement.innerHTML ='<div class="animated shake alert alert-danger alert-bordered alert-dismissable"><a href="#" class="close" style="margin-right:1%;" data-dismiss="alert" aria-label="close">&times;</a><ul>'+errorlist+'</ul></div>';
        setTimeout(() => { this.el.nativeElement.innerHTML = '';},10000);
        window.scrollTo(0,0);
    }

    ResetMessage()
    {
        this.el.nativeElement.innerHTML = "";
    }
}
