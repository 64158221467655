import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from './auth.service';
import { Observable } from 'rxjs';
import { AppConfig } from '../../app.config';
import { BatchSettings } from '../models/request/batchSettings';
import { SSCCSettings } from '../models/request/ssccsettings.model';
import {throwError as observableThrowError } from 'rxjs';

import {catchError} from 'rxjs/operators';

@Injectable()
export class MasterSettingsService {

  private accountSettingUrl = 'api/mastersettings';
  private batchSettingUrl = 'api/batchsettings';
  private codeStatusSettingUrl = 'api/codestatussettings';
  private packageSettingUrl = 'api/packagesettings';
  private customerSettingUrl = 'api/customerMaster';
  private ssccSettingUrl = 'api/ssccsetting';
  private regulatorySettingUrl = 'api/regulatoryMaster';
  private systemSettingUrl = 'api/systemsettings';

  constructor(private http: HttpClient, private auth: AuthenticationService) { }

//#region  Account settings

  updateMasterSettings(strCmpId,masterSettings){
    let authHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.auth.getAuthToken() });
    return this.http.post(AppConfig.settings.endpoint  + this.accountSettingUrl + '/update', JSON.stringify({companyId: strCmpId, masterSettings : masterSettings}), { headers: authHeader }).pipe(catchError(e => this.handleError(e)));
  }

  getMasterSettings(strCmpId){
    let authHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.auth.getAuthToken() });
    return this.http.post(AppConfig.settings.endpoint  + this.accountSettingUrl + '/get', JSON.stringify({companyId: strCmpId}), { headers: authHeader }).pipe(catchError(e => this.handleError(e)));
  }

  getDateTimeSettings(){
    let authHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.auth.getAuthToken() });
    return this.http.get(AppConfig.settings.endpoint  + this.accountSettingUrl + '/getdatetimeinfo', { headers: authHeader }).pipe(catchError(e => this.handleError(e)));
  }

  takeDatabaseBackup()
  {
    let authHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.auth.getAuthToken() });
    return this.http.post(AppConfig.settings.endpoint  + this.accountSettingUrl + '/databackup', JSON.stringify({}), { headers: authHeader }).pipe(catchError(e => this.handleError(e)));
  }

  GetDatabaseBackupList()
  {
    let authHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.auth.getAuthToken() });
    return this.http.post(AppConfig.settings.endpoint  + this.accountSettingUrl + '/databackuplist', JSON.stringify({}), { headers: authHeader }).pipe(catchError(e => this.handleError(e)));
  }

  RestoreDatabaseBackup(dbBackupObj,backupRequired,password,Superadminname,SuperadminPassword)
  {
    let authHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.auth.getAuthToken() });
    return this.http.post(AppConfig.settings.endpoint + this.accountSettingUrl + '/databackuprestore', JSON.stringify({dbBackupObj : dbBackupObj,BackupRequired:backupRequired,password:password,Superadminname:Superadminname,SuperadminPassword:SuperadminPassword}), { headers: authHeader }).pipe(catchError(e => this.handleError(e)));
  }

  //#endregion

//#region Batch Settings

  getBatchSettings(strCmpId){
    let authHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.auth.getAuthToken() });
    return this.http.post(AppConfig.settings.endpoint  + this.batchSettingUrl + '/get', JSON.stringify({companyId: strCmpId}), { headers: authHeader }).pipe(catchError(e => this.handleError(e)));
  }

  updateBatchStatusSettings(strCmpId,batchSettings,updateisActive){
    let authHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.auth.getAuthToken() });
    return this.http.post(AppConfig.settings.endpoint  + this.batchSettingUrl + '/updatestatus', JSON.stringify({companyId: strCmpId, batchSettings : batchSettings,updateIsActive:updateisActive}), { headers: authHeader }).pipe(catchError(e => this.handleError(e)));
  }

  updateBatchSettings(batchSettings : BatchSettings){
    let authHeader = new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getAuthToken() });
    return this.http.post(AppConfig.settings.endpoint  + this.batchSettingUrl + '/update', batchSettings , { headers: authHeader }).pipe(catchError(e => this.handleError(e)));
  }
  
  addNewBatchStatus(strCmpId,batchStatusSettings){
    let authHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.auth.getAuthToken() });
    return this.http.post(AppConfig.settings.endpoint  + this.batchSettingUrl + '/add', JSON.stringify({companyId: strCmpId, batchStatusSettings: batchStatusSettings}), { headers: authHeader }).pipe(catchError(e => this.handleError(e)));
  }

  
  //#endregion
  
  //#region Serial Number Status Settings

  getCodeStatusSettings(strCmpId){
    let authHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.auth.getAuthToken() });
    return this.http.post(AppConfig.settings.endpoint  + this.codeStatusSettingUrl + '/get', JSON.stringify({companyId: strCmpId}), { headers: authHeader }).pipe(catchError(e => this.handleError(e)));
  }

  updateCodeStatusSettings(strCmpId,codeStatusSettings,updateisActive){
    let authHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.auth.getAuthToken() });
    return this.http.post(AppConfig.settings.endpoint  + this.codeStatusSettingUrl + '/update', JSON.stringify({companyId: strCmpId, codeStatusSettings : codeStatusSettings,updateIsActive:updateisActive}), { headers: authHeader }).pipe(catchError(e => this.handleError(e)));
  }
  addNewCodeStatus(strCmpId,codeStatusSettings){
    let authHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.auth.getAuthToken() });
    return this.http.post(AppConfig.settings.endpoint  + this.codeStatusSettingUrl + '/add', JSON.stringify({companyId: strCmpId, codeStatusSettings: codeStatusSettings}), { headers: authHeader }).pipe(catchError(e => this.handleError(e)));
  }
  //#endregion

  //#region Package Settings
  getPackageSettings(strCmpId){
    let authHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.auth.getAuthToken() });
    return this.http.post(AppConfig.settings.endpoint  + this.packageSettingUrl + '/get', JSON.stringify({companyId: strCmpId}), { headers: authHeader }).pipe(catchError(e => this.handleError(e)));
  }

  updatePackageLevelSettings(strCmpId,levelSettings,updateisActive){
    let authHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.auth.getAuthToken() });
    return this.http.post(AppConfig.settings.endpoint  + this.packageSettingUrl + '/updatelevel', JSON.stringify({companyId: strCmpId, levelSettings : levelSettings,updateIsActive:updateisActive}), { headers: authHeader }).pipe(catchError(e => this.handleError(e)));
  }

  addNewPackageLevel(strCmpId,levelSettings){
    let authHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.auth.getAuthToken() });
    return this.http.post(AppConfig.settings.endpoint  + this.packageSettingUrl + '/addlevel', JSON.stringify({companyId: strCmpId, levelSettings: levelSettings}), { headers: authHeader }).pipe(catchError(e => this.handleError(e)));
  }

  getPackageLevelsGrpType(strCmpId){
    let authHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.auth.getAuthToken() });
    return this.http.post(AppConfig.settings.endpoint  + this.packageSettingUrl + '/getpackagelevelsgrptype', JSON.stringify({companyId: strCmpId}), { headers: authHeader }).pipe(catchError(e => this.handleError(e)));
  }
  //#endregion

 //#region Master Privilages
  getMasterPrivileges(strCmpId)
  {
    let authHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.auth.getAuthToken() });
    return this.http.post(AppConfig.settings.endpoint  + this.accountSettingUrl + '/getmasterprivileges', JSON.stringify({companyId: strCmpId}), { headers: authHeader }).pipe(catchError(e => this.handleError(e)));
  }
  //#endregion

  //#region Package Settings
  getCustomerMaster(){
    let authHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.auth.getAuthToken() });
    return this.http.get(AppConfig.settings.endpoint  + this.customerSettingUrl + '/get', { headers: authHeader }).pipe(catchError(e => this.handleError(e)));
  }
  
  updateCustomer(customer,updateisActive){
    let authHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.auth.getAuthToken() });
    return this.http.post(AppConfig.settings.endpoint  + this.customerSettingUrl + '/updatecustomer', JSON.stringify({customer: customer, updateIsActive:updateisActive}), { headers: authHeader }).pipe(catchError(e => this.handleError(e)));
  }
  
  addNewCustomer(customer){
    let authHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.auth.getAuthToken() });
    return this.http.post(AppConfig.settings.endpoint  + this.customerSettingUrl + '/addcustomer', customer, { headers: authHeader }).pipe(catchError(e => this.handleError(e)));
  }
  //#endregion

  //#region SystemSetting

  /*** Service used for call getsystemsetting API */
  getCodeGenerationTypeList() {
    let authHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.auth.getAuthToken() });
    return this.http.get(AppConfig.settings.endpoint + this.systemSettingUrl + '/getsystemsetting', { headers: authHeader }).pipe(catchError(e => this.handleError(e)));
  }

  /**
   * This service is used for call updatecodetype API
   * @param SystemSettings
   * @param updateisActive
   */
  updateCodeGenerationType(SystemSettings, updateisActive) {
    let authHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.auth.getAuthToken() });
    return this.http.post(AppConfig.settings.endpoint + this.systemSettingUrl + '/updatecodetype', JSON.stringify({ SystemSettings: SystemSettings, updateIsActive: updateisActive }), { headers: authHeader }).pipe(catchError(e => this.handleError(e)));
  }

  /**
 * This service is used for call updatebatchsystemsetting API
 * @param SystemSettings
 * @param updateisActive
 */
  updateBatchSystemSetting(SystemSettings, updateisActive) {
    let authHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.auth.getAuthToken() });
    return this.http.post(AppConfig.settings.endpoint + this.systemSettingUrl + '/updatebatchsystemsetting', JSON.stringify({ SystemSettings: SystemSettings, updateIsActive: updateisActive }), { headers: authHeader }).pipe(catchError(e => this.handleError(e)));
  }

  //#endregion

  //#region SSCCRange Settings

  getSSCCRanges(companyid: string) {
    let authHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.auth.getAuthToken() });
    return this.http.get(AppConfig.settings.endpoint  + this.ssccSettingUrl + '/getranges?companyid=' + companyid , { headers: authHeader }).pipe(catchError(e => this.handleError(e)));
  }

  getSSCCRangeDetails(rangeid: string) {
    let authHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.auth.getAuthToken() });
    return this.http.get(AppConfig.settings.endpoint  + this.ssccSettingUrl + '/getrangedetails?rangeid=' + rangeid , { headers: authHeader }).pipe(catchError(e => this.handleError(e)));
  }

  createSSCCRange(range: SSCCSettings) {
    let authHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.auth.getAuthToken() });
    return this.http.post(AppConfig.settings.endpoint  + this.ssccSettingUrl + '/createssccrange', range, { headers: authHeader }).pipe(catchError(e => this.handleError(e)));
  }

  getSSCCNumbers(rangeid: string) {
    let authHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.auth.getAuthToken() });
    return this.http.get(AppConfig.settings.endpoint  + this.ssccSettingUrl + '/getssccnumbers?rangeid=' + rangeid , { headers: authHeader }).pipe(catchError(e => this.handleError(e)));
  }

  updateSSCCRangeStatus(range: SSCCSettings)
  {
    let authHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.auth.getAuthToken() });
    return this.http.put(AppConfig.settings.endpoint  + this.ssccSettingUrl + '/updatessccrangestatus', range, { headers: authHeader }).pipe(catchError(e => this.handleError(e)));
  }

  getSSCCNumberCount() {
    let authHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.auth.getAuthToken() });
    return this.http.get(AppConfig.settings.endpoint  + this.ssccSettingUrl + '/ssccnumbercounts' , { headers: authHeader }).pipe(catchError(e => this.handleError(e)));
  }

  updateRegulatory(Regulatory,updateisActive){
    let authHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.auth.getAuthToken() });
    return this.http.post(AppConfig.settings.endpoint  + this.regulatorySettingUrl + '/updateregulatory', JSON.stringify({Regulatory: Regulatory, updateIsActive:updateisActive}), { headers: authHeader }).pipe(catchError(e => this.handleError(e)));
  }

  //#endregion


  private handleError(error: any): Observable<any> {
    let errresponse: Observable<any>;
    switch (error.status) {
        case 400:
            if(error.hasOwnProperty("ModelState"))
            {
                let errors = [];
                let validationErrorDictionary = JSON.parse(JSON.stringify(error.ModelState));
                for (var fieldName in validationErrorDictionary) {
                    if (validationErrorDictionary.hasOwnProperty(fieldName)) {
                        errors.push(validationErrorDictionary[fieldName]);
                    }
                }
                errresponse = observableThrowError(errors);
            }
            else
            {
                errresponse = observableThrowError(error.error.Message);
            }
            break;

        case 401:
            errresponse = observableThrowError("<b>Authorization Failed </b>" + error.statusText);
            break;

        case 403:
        case 500:
            errresponse = observableThrowError(error.statusText);
        break;

        default:
            errresponse = observableThrowError("<b>Unable to connect to the server </b>" + error.statusText);
            break;
    }
    return errresponse;

}
}
